import { createApi } from '@reduxjs/toolkit/query/react';
import { authedExtendFetchBaseQuery } from '../extend';

const exportApi = createApi({
	reducerPath: 'export',
	baseQuery: authedExtendFetchBaseQuery(),
	endpoints(builder) {
		return {
			searchUnit: builder.query({
				query: name => {
					return {
						method: 'GET',
						url: `search/units?name=${name}`
					};
				}
			}),
			exportReport: builder.query({
				query: query => {
					return {
						method: 'GET',
						url: `report?id=${query.id}&type=${query.type}&from=${query.from}&to=${query.to}`,
						responseHandler: async response => {
							if (response.status === 200) {
								window.location.assign(window.URL.createObjectURL(await response.blob()));
							}
						}
					};
				}
			})
		};
	}
});

export const { useSearchUnitQuery, useExportReportQuery } = exportApi;

export { exportApi };
