import useModalContext from '../../hooks/useModalContext';
import Image from '../Image';
import Modal from './Modal';
import useWialonLogin from '../../hooks/useWialonLogin';
import { useSelector } from 'react-redux';

const Layout = ({ children }) => {
	//
	const { modal } = useModalContext();
	const { logout } = useWialonLogin();
	const wialonAuth = useSelector(state => state.wialonAuth);

	return (
		<>
			{modal && <Modal />}
			<header>
				<div className="container">
					<nav className="level is-mobile">
						<div className="level-left">
							<div className="level-item">
								<Image src="logo.png" />
							</div>
						</div>
						<div className="level-right">
							<div className="level-item">
								<button className="button is-danger is-light" onClick={logout}>
									Logout
								</button>
							</div>
						</div>
					</nav>
				</div>
			</header>

			<div className="container main">{wialonAuth.logged && children}</div>
		</>
	);
};

export default Layout;
