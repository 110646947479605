import { createContext } from 'react';
import { ModalProvider } from './modal';
const AppContext = createContext();

function AppProvider({ children }) {
	return (
		<AppContext.Provider value="">
			<ModalProvider>{children}</ModalProvider>
		</AppContext.Provider>
	);
}

export { AppProvider };
export default AppContext;
