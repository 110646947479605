import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LOGIN_URL } from '../config/app';

const extendFetchBaseQuery = (args, api, extraOptions) => {
	return fetchBaseQuery(
		{
			baseUrl: process.env.REACT_APP_BACKEND_URL,
			...args,
			prepareHeaders: (headers, { getState }) => {
				headers.set('x-tracer-access-token', getState().wialonAuth.access_token);
				headers.set('x-tracer-sid', getState().wialonAuth.sid);

				return headers;
			}
		},
		api,
		extraOptions
	);
};

export const authedExtendFetchBaseQuery = (args, api, extraOptions) => {
	//
	const baseQuery = extendFetchBaseQuery(args, api, extraOptions);

	return async (args, api, extraOptions) => {
		const result = await baseQuery(args, api, extraOptions);

		if (result.error && result.error.status === 401) {
			window.location.replace(LOGIN_URL);
		}

		return result;
	};
};
