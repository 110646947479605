import { createContext, useState } from 'react';

const ModalContext = createContext();

function ModalProvider({ children }) {
	const [modal, setModal] = useState(false);
	const [title, setTitle] = useState('');

	const closeModal = () => {
		setModal(false);
	};

	const openModal = (value, title = '') => {
		setModal(value);
		setTitle(title);
	};

	const contextShare = {
		modal,
		title,
		closeModal,
		openModal
	};

	return <ModalContext.Provider value={contextShare}>{children}</ModalContext.Provider>;
}

export { ModalProvider };
export default ModalContext;
