import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { wialonLoginSlice } from './slices/wialonLoginSlice';
import { exportApi } from './apis/exportApi';
import { appApi } from './apis/appApi';
import { wialonLoginApi } from './apis/wialonLoginApi';

const store = configureStore({
	reducer: {
		wialonAuth: wialonLoginSlice.reducer,
		[exportApi.reducerPath]: exportApi.reducer,
		[appApi.reducerPath]: appApi.reducer,
		[wialonLoginApi.reducerPath]: wialonLoginApi.reducer
	},
	middleware: getDefaultMiddleware => {
		return getDefaultMiddleware().concat([exportApi.middleware, appApi.middleware, wialonLoginApi.middleware]);
	}
});

setupListeners(store.dispatch);

export { store, wialonLoginSlice };

export const { setAccessToken, setSid, setLogged, logout } = wialonLoginSlice.actions;

export { useSearchUnitQuery, useExportReportQuery } from './apis/exportApi';
export { useConfigQuery } from './apis/appApi';
export { useLoginQuery } from './apis/wialonLoginApi';
