import { createApi } from '@reduxjs/toolkit/query/react';
import { authedExtendFetchBaseQuery } from '../extend';

const wialonLoginApi = createApi({
	reducerPath: 'wialonLogin',
	baseQuery: authedExtendFetchBaseQuery(),
	endpoints(builder) {
		return {
			login: builder.query({
				query: () => {
					return {
						method: 'GET',
						url: `login`
					};
				}
			})
		};
	}
});

export const { useLoginQuery } = wialonLoginApi;
export { wialonLoginApi };
