import { useEffect, useState } from 'react';
import Loading from '../Loading';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useConfigQuery, useExportReportQuery, useSearchUnitQuery } from '../../store';
import moment from 'moment';

const ExportPage = () => {
	//
	const [search, setSearch] = useState('');
	const [results, setResults] = useState();
	const [unit, setUnit] = useState();
	const [from, setFrom] = useState('');
	const [to, setTo] = useState('');
	const [report, setReport] = useState(null);
	const [errorMessage, setErrorMessage] = useState();

	const { data: configData, error: configError } = useConfigQuery();

	const {
		data: searchData,
		isFetching: searchIsFetching,
		error: searchError
	} = useSearchUnitQuery(search, {
		skip: search.length === 0
	});

	const { isFetching: reportIsFetching, error: reportError } = useExportReportQuery(report, {
		skip: report === null
	});

	const handleSearch = e => {
		setSearch(e.target.value);
		setUnit();
		setErrorMessage();
		setResults();
	};

	const handleSelectUnit = unit => {
		setUnit(unit);
		setSearch('');
		setResults();
	};

	const handleExportReport = type => {
		setErrorMessage();
		setReport({
			id: unit.id,
			type,
			from: from ? moment(from).format('YYYY-MM-DD') : '',
			to: to ? moment(to).format('YYYY-MM-DD') : ''
		});
	};

	useEffect(() => {
		if (!searchData) {
			setResults();
		} else {
			setResults(
				searchData.map(unit => {
					return (
						<li key={unit.id}>
							<span
								className="tag is-info is-light is-medium"
								onClick={() => {
									handleSelectUnit(unit);
								}}
							>
								{unit.name}
							</span>
						</li>
					);
				})
			);
		}
	}, [searchData]);

	useEffect(() => {
		if (searchError || configError || reportError) {
			setErrorMessage(reportError?.status === 404 ? 'No data found for report...' : 'Looks like an error occurred');
		}
	}, [searchError, configError, reportError]);

	return (
		<div className="reports">
			<div className="box">
				<input
					className="input is-medium"
					type="text"
					placeholder="Search unit by name or IMEI"
					value={search}
					onChange={handleSearch}
				/>
			</div>

			<div className="results">
				{results && (
					<div className="box">
						<ul>{results}</ul>
					</div>
				)}
				{searchIsFetching && <Loading />}
			</div>

			{unit && (
				<>
					<h1>{unit.name}</h1>
					<div className="box">
						<h3>Select dates:</h3>
						<div className="field">
							<div className="control">
								<DatePicker
									dateFormat="dd/MM/yyyy"
									className="input is-medium"
									placeholderText="From"
									selected={from}
									onChange={date => setFrom(date)}
								/>
							</div>
						</div>
						<div className="field">
							<div className="control">
								<DatePicker
									dateFormat="dd/MM/yyyy"
									className="input is-medium"
									placeholderText="To"
									selected={to}
									onChange={date => setTo(date)}
								/>
							</div>
						</div>
					</div>
					{reportIsFetching ? (
						<Loading />
					) : (
						<>
							<h3>Download CSV:</h3>
							<button
								className="button"
								onClick={() => {
									handleExportReport(configData.reports.trips);
								}}
							>
								Trips
							</button>{' '}
							<button
								className="button"
								onClick={() => {
									handleExportReport(configData.reports.parkings);
								}}
							>
								Parkings
							</button>{' '}
							<button
								className="button"
								onClick={() => {
									handleExportReport(configData.reports.speedings);
								}}
							>
								Speedings
							</button>
						</>
					)}
				</>
			)}
			{errorMessage && (
				<div className="box">
					<article className="message is-danger">
						<div className="message-body">{errorMessage}</div>
					</article>
				</div>
			)}
		</div>
	);
};

export default ExportPage;
