import { useDispatch } from 'react-redux';
import { logout, setAccessToken, setLogged, setSid, useLoginQuery } from '../store';
import { useEffect } from 'react';
import { LOGIN_URL } from '../config/app';

const useWialonLogin = () => {
	//
	const dispatch = useDispatch();

	useEffect(() => {
		const access_token = new URLSearchParams(window.location.search).get('access_token');

		if (access_token) {
			sessionStorage.setItem('access_token', access_token);
			dispatch(setAccessToken(access_token));
		}

		const sid = new URLSearchParams(window.location.search).get('sid');

		if (sid) {
			sessionStorage.setItem('sid', sid);
			dispatch(setSid(sid));
		}

		// Call server side to check
	}, [dispatch]);

	// Check login status, needed for "logged" state to prevent further rendering
	const { isFetching, isError } = useLoginQuery();

	useEffect(() => {
		if (!isFetching && !isError) {
			dispatch(setLogged(true));
			sessionStorage.setItem('logged', '1');
		}
	}, [isFetching, isError, dispatch]);

	return {
		logout: () => {
			//
			dispatch(logout());
			dispatch(setLogged(false));

			sessionStorage.removeItem('access_token');
			sessionStorage.removeItem('sid');
			sessionStorage.removeItem('logged');

			window.location.replace(LOGIN_URL);
		}
	};
};

export default useWialonLogin;
