import { createApi } from '@reduxjs/toolkit/query/react';
import { authedExtendFetchBaseQuery } from '../extend';

const appApi = createApi({
	reducerPath: 'app',
	baseQuery: authedExtendFetchBaseQuery(),
	endpoints(builder) {
		return {
			config: builder.query({
				query: name => {
					return {
						method: 'GET',
						url: `config`
					};
				}
			})
		};
	}
});

export const { useConfigQuery } = appApi;
export { appApi };
