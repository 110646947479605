import { useEffect } from 'react';
import useModalContext from '../../hooks/useModalContext';

function Modal() {
	const { modal, title, closeModal } = useModalContext();

	useEffect(() => {
		// Elements that trigger "close"
		const elements =
			document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button') || [];

		// Handlers
		const clickHandler = event => {
			closeModal();
		};

		const keydownHandler = event => {
			const e = event || window.event;

			if (e.keyCode === 27) {
				closeModal();
			}
		};

		document.documentElement.classList.add('is-clipped');

		// Attach Handlers
		elements.forEach(element => {
			element.addEventListener('click', clickHandler);
		});

		document.addEventListener('keydown', keydownHandler);

		// Detach Handlers before next render
		return () => {
			document.removeEventListener('keydown', keydownHandler);

			elements.forEach(element => {
				element.removeEventListener('click', clickHandler);
			});
			document.documentElement.classList.remove('is-clipped');
		};
		// eslint-disable-next-line
	}, []);

	return (
		<div className="modal is-active">
			<div className="modal-background"></div>
			<div className="modal-card">
				<header className="modal-card-head">
					<p className="modal-card-title">{title}</p>
					<button className="delete" aria-label="close"></button>
				</header>
				<section className="modal-card-body">{modal}</section>
			</div>
		</div>
	);
}
export default Modal;
